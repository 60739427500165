<template>
    <transition name="slide">
      <div v-if="show" class="fixed top-0 left-0 right-0 bg-blue-600 text-white p-4 z-50 transform ease-out duration-300">
        <div class="container mx-auto text-sm sm:text-base flex justify-between items-center">
          <p>
            We use cookies to improve your experience and analyze site traffic. By clicking "Accept," you consent to our use of cookies. For more information, read our <router-link to="/privacy-policy" class="underline">Privacy Policy</router-link>.
          </p>
          <div class="flex space-x-2">
            <button @click="acceptCookies" class="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Accept
            </button>
            <button @click="declineCookies" class="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
              Decline
            </button>
          </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  
  export default {
    name: 'CookieConsent',
    setup() {
      const show = ref(false);
  
      function acceptCookies() {
        show.value = false;
        localStorage.setItem('cookieConsent', 'accepted');
      }
  
      function declineCookies() {
        show.value = false;
        // Optionally perform additional actions on decline
        localStorage.setItem('cookieConsent', 'declined');
      }
  
      onMounted(() => {
        const consentStatus = localStorage.getItem('cookieConsent');
        if (consentStatus !== 'accepted' && consentStatus !== 'declined') {
          show.value = true;
        }
      });
  
      return { show, acceptCookies, declineCookies };
    }
  };
  </script>
  
  <style scoped>
  .slide-enter-active, .slide-leave-active {
    transition: transform 0.5s ease;
  }
  .slide-enter-from, .slide-leave-to {
    transform: translateY(-100%);
  }
  </style>
  