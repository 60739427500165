import { createRouter, createWebHistory } from 'vue-router';

const TermsAndConditions = () => import('@/views/TermsAndConditions.vue');
const HomePage = () => import('@/views/HomePage.vue');
const PrivacyPolicyView = () => import('@/views/PrivacyPolicyView.vue');
const CookiePolicyView = () => import('@/views/CookiePolicyView.vue');
const ThankYouPage = () => import('@/views/ThankYouPage.vue'); // Added line

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage
    },
    {
        path: '/terms-and-conditions',
        name: 'TermsAndConditions',
        component: TermsAndConditions
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicyView
    },
    {
        path: '/cookie-policy',
        name: 'CookiePolicy',
        component: CookiePolicyView
    },
    {
        path: '/thank-you', // New ThankYouPage route
        name: 'ThankYouPage',
        component: ThankYouPage
    },
    // Catch-all route
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/NotFound.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
