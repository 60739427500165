<template>
  <nav class="bg-gradient-to-r from-blue-800 to-blue-900 shadow">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="flex h-20 justify-between items-center relative text-white">
        <!-- Logo on the left side - links back to home -->
        <router-link to="/" class="flex flex-shrink-0 items-center">
          <img class="h-8 w-auto" src="@/assets/flag-of-canada.png" alt="Your Company" />
        </router-link>

        <!-- Centered Logo - Adjusted positioning and size - links back to home -->
        <router-link to="/" class="absolute left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 z-10">
          <img src="@/assets/frf-logo.png" alt="FIXED-INTEREST.BOND Logo" class="h-8 sm:h-10 md:h-12 w-auto" />
        </router-link>

        <!-- Right side content of nav, updated button color and removed gradient -->
        <div class="z-10 hidden md:block">
          <a href="#rateComparisonForm" v-if="showCTA" class="bg-yellow-500 text-black px-4 sm:px-6 py-2 text-lg font-semibold rounded-md shadow-lg hover:shadow-xl hover:scale-105 transition-all duration-300 transform">
            Get Your Guide Now!
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref } from 'vue';
const showCTA = ref(true);
</script>
