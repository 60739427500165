<template>
    <div class="bg-blue-600 text-white py-6 border-t border-blue-500">
        <!-- Container -->
        <div class="container mx-auto px-4 text-center">
            <p class="text-sm sm:text-base md:text-lg mb-4 font-light">
                <strong>Disclaimer:</strong> The information provided on this website is for informational purposes only and should not be construed as financial advice. Please consult with a qualified financial advisor before making any financial decisions.
            </p>
            
            <!-- Legal and Address Information -->
            <p class="text-lg font-medium mb-2">
                &copy; 2024 Fixed Rate Finder, a trading name of MK TRADING INTERNATIONAL LTD. All rights reserved.
            </p>
            <p class="text-sm sm:text-base md:text-lg mb-4 font-light">
                777 Hornby Street, Vancouver, BC V6Z 1S4, Canada | 158 Derwent Drive, Slough, SL1 6HP, United Kingdom • Company number: 14504269
            </p>

            <!-- Contact Information -->
            <p class="text-sm sm:text-base md:text-lg mb-4 font-light">
                For inquiries, please contact us at: <a href="tel:+1-604-555-1234" class="underline hover:text-white">+1-604-555-1234</a> | <a href="tel:+44-07503211792" class="underline hover:text-white">+44 07503211792</a>
            </p>

            <!-- Regulatory and Dispute Resolution Information -->
            <p class="text-sm sm:text-base md:text-lg font-light mb-4">
                For more information about the risks associated with saving and how your deposits are protected, please visit the <a href="https://www.cdic.ca/" class="underline hover:text-white">Canada Deposit Insurance Corporation (CDIC)</a> website. If you have any disputes with a financial service provider, you can also contact the <a href="https://www.fcac-acfc.gc.ca/eng/index.html" class="underline hover:text-white">Financial Consumer Agency of Canada (FCAC)</a> for guidance and assistance.
            </p>

            <!-- Links to Terms and Conditions, Privacy Policy, and Cookie Policy -->
            <div class="mt-2 space-x-4">
                <router-link to="/terms-and-conditions" class="text-white hover:underline">Terms and Conditions</router-link>
                <span>|</span>
                <router-link to="/privacy-policy" class="text-white hover:underline">Privacy Policy</router-link>
                <span>|</span>
                <router-link to="/cookie-policy" class="text-white hover:underline">Cookie Policy</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterSection'
}
</script>

<style scoped>
/* Tailwind classes handle the styling, with potential for additional styles if needed. */
</style>
