<template>
  <div class="font-sans antialiased text-center text-gray-700 bg-gradient-to-r from-blue-600 to-blue-400">
    <!-- Use the navBarVisible property to control visibility -->
    <NavBar v-if="navBarVisible" />
    <CookieConsent />
    <router-view />
    <FooterSection />
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import NavBar from './components/NavBar.vue';
import FooterSection from './components/FooterSection.vue';
import CookieConsent from './components/CookieConsent.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    FooterSection,
    CookieConsent
  },
  setup() {
    const route = useRoute();
    const navBarVisible = ref(true); // Default visibility is true

    // Watch for route changes
    watch(() => route.path, (newPath) => {
      // Update navBarVisible based on the route
      // For example, hide on the Thank You page
      navBarVisible.value = newPath !== '/thank-you';
    });

    return { navBarVisible };
  }
};
</script>

<style>
/* Styles are managed by Tailwind CSS */
</style>
